import React, {useMemo} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./option.module.scss";

export function Option({
  innerRef,
  innerProps,
  children,
  isFocused,
  data: {type}
}) {
  const typeElement = useMemo(() => {
    if (type !== `Тег`) {
      return (
        <div
          className={classnames(styles.type, {
            [styles.typeExistingDesign]: type === `готовые дизайны`,
            [styles.typeOwnDesign]: type === `свой дизайн`
          })}
        >
          {type}
        </div>
      );
    }
  }, [type]);

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classnames(styles.option, {
        [styles.optionFocused]: isFocused
      })}
    >
      <span className={styles.label}>{children}</span>
      {typeElement}
    </div>
  );
}

Option.propTypes = {
  children: PropTypes.string,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  data: PropTypes.object
};
