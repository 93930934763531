import React from 'react'
import { Pane } from '../../HeaderApp/components/Pane'
import styled from 'styled-components'
import Search from '../../HeaderApp/components/Search'
import { Cart } from '../../HeaderApp/components/Cart'
import Profile from './Profile'
import { SubMenus } from '../../HeaderApp/components/SubMenus'
import styles from '../custom-store-header.module.scss'
import { Context } from '../../HeaderApp'
import { defaultState } from '../../HeaderApp/defaultState'
import { useSelector } from 'react-redux'
import {
  isMobileSelector,
  customStoreSelector,
  showBannerSelector,
} from '$common/selectors'

const Root = () => {
  const state = useSelector((state) => state)
  const isMobile = useSelector(isMobileSelector)
  const customStore = useSelector(customStoreSelector)
  const showBanner = useSelector(showBannerSelector)

  return (
    <Context.Provider value={{ ...defaultState, ...state }}>
      <Pane isCustomStore />
      <Container>
        {!isMobile && (
          <SearchContainer>
            <Search isCustomStore />
            <Cart />
          </SearchContainer>
        )}

        {showBanner && (
          <BannerContainer>
            <StyledImage src={customStore.bannerBackgroundImage} />
          </BannerContainer>
        )}

        <Profile
          productsCount={customStore.productsCount}
          owner={customStore.owner}
          storeSubdomain={customStore.storeSubdomain}
          customStoreName={customStore.customStoreName}
          ownerAvatarUrl={customStore.ownerAvatarUrl}
        />

        {showBanner && !isMobile && (
          <SubMenus
            isCustomStore
            className={styles.menu}
            subMenus={state.menu[1].children}
            isFocused={true}
            hide={() => {}}
            keepFocused={() => {}}
          />
        )}
      </Container>
    </Context.Provider>
  )
}

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  position: static;

  @media (max-width: 790px) {
    max-height: 400px;
    width: auto;
    max-width: calc(100% + 42px);
    justify-content: center;
  }
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1180px;
  padding: 0 40px;

  @media (max-width: 940px) {
    padding: 0 21px;
    padding-top: 80px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 32px 0;
`

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1180px;
  height: fit-content;
  max-height: 400px;
  border-radius: 8px;
  background: transparent;
  margin-bottom: 16px;
  align-items: center;

  @media (min-width: 791px) {
    overflow: hidden;
  }
`

export default Root
