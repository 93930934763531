import React from 'react'

const ShareIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_647_467)">
      <path
        d="M12 5.44098C13.1046 5.44098 14 4.54555 14 3.44098C14 2.33641 13.1046 1.44098 12 1.44098C10.8954 1.44098 10 2.33641 10 3.44098C10 4.54555 10.8954 5.44098 12 5.44098Z"
        stroke="#2A3133"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10.1077C5.10457 10.1077 6 9.21224 6 8.10767C6 7.0031 5.10457 6.10767 4 6.10767C2.89543 6.10767 2 7.0031 2 8.10767C2 9.21224 2.89543 10.1077 4 10.1077Z"
        stroke="#2A3133"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.7744C13.1046 14.7744 14 13.8789 14 12.7744C14 11.6698 13.1046 10.7744 12 10.7744C10.8954 10.7744 10 11.6698 10 12.7744C10 13.8789 10.8954 14.7744 12 14.7744Z"
        stroke="#2A3133"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.72668 9.11432L10.28 11.7677"
        stroke="#2A3133"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2734 4.44769L5.72668 7.10103"
        stroke="#2A3133"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_647_467">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.107666)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default ShareIcon
