import styled from 'styled-components'

export default {
  ProfileWrapper: styled.div`
    display: flex;
    border-radius: 8px;
    border: 1px solid #dfe0e0;
    padding: 24px;
    justify-content: space-between;
    margin-bottom: 48px;

    @media (max-width: 790px) {
      flex-direction: column;
      padding: 16px;
    }
  `,

  LinksWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 790px) {
      justify-content: center;
    }
  `,

  ContentWrapper: styled.div`
    display: flex;
    gap: 24px;
    align-items: center;

    @media (max-width: 790px) {
      border-bottom: 1px solid #dfe0e0;
      padding-bottom: 16px;
      margin-bottom: 16px;
      gap: 12px;
    }
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `,

  Text: styled.div`
    display: flex;
    color: ${(p) => p.theme.colors.black};
    font-family: Formular;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  `,

  ShareButton: styled.div`
    cursor: pointer;
    color: ${(p) => p.theme.colors.black};
    font-family: Formular;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
  `,

  SeparateVBar: styled.div`
    width: 1px;
    height: 48px;
    background: #dfe0e0;
    margin-right: 24px;

    @media (max-width: 790px) {
      display: none;
    }
  `,

  OwnerLink: styled.a`
    color: ${(p) => p.theme.colors.black} !important;
    font-family: Formular;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
  `,

  Title: styled.div`
    color: ${(p) => p.theme.colors.black};
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    width: 100%;
  `,

  StoreInfo: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width: 790px) {
      gap: 12px;
    }
  `,

  EllipseIcon: styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #c9cbcc;
  `,

  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    height: 68px;
    width: 68px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #d1d1d1;
    background: ${(p) => p.theme.colors.bg.secondary};
    margin: 0;

    @media (max-width: 790px) {
      height: 55px;
      width: 55px;
    }
  `,

  ProfileImage: styled.img`
    height: 100%;
  `,

  HideOnMobile: styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 790px) {
      gap: 12px;
    }

    @media (max-width: 500px) {
      display: none;
    }
  `,
}
