import React from 'react'
import Root from './components/Root'
import { theme } from '../styles/theme'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import headerStore, {
  initHeaderStoreAction,
} from '../HeaderApp/store'

const App = ({ state, materialUI }) => {
  headerStore.dispatch(initHeaderStoreAction(state))

  return (
    <Provider store={headerStore}>
      <ThemeProvider theme={theme}>
        <Root />
      </ThemeProvider>
    </Provider>
  )
}

export default App
