import React, { useEffect, useState } from 'react'
import ShareIcon from '../../../../icons/ShareIcon'
import GenericMessage from '../../../common/GenericMessage'
import Styled from './styles.js'

const Profile = (props) => {
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState(false)
  const [messageText, setMessageText] = useState('')

  const storeName = props.customStoreName
    ? props.customStoreName
    : props.storeSubdomain

  const wordDeclination = (num, wordBase) => {
    if (num > 4 && num < 15) return num + ' ' + wordBase + 'ов'
    else {
      if (num % 10 === 1) return num + ' ' + wordBase
      if (num % 10 > 1 && num % 10 < 5) return num + ' ' + wordBase + 'а'
      return num + ' ' + wordBase + 'ов'
    }
  }

  const copyLink = () => {
    if (navigator.clipboard)
      navigator.clipboard
        .writeText(location.href)
        .then(() => {
          setMessage(true)
          setMessageText('Ссылка скопирована в буфер обмена')
          setStatus('success')
        })
        .catch(() => {
          setMessage(true)
          setMessageText('Не удалось скопировать ссылку')
          setStatus('error')
        })
    else {
      setMessage(true)
      setMessageText('Не удалось скопировать ссылку')
      setStatus('error')
    }
  }

  useEffect(() => {
    message && setTimeout(() => setMessage(false), 3000)
  }, [message])

  return (
    <Styled.ProfileWrapper>
      <Styled.ContentWrapper>
        <a href="/">
          <Styled.ImageContainer>
            <Styled.ProfileImage src={props.owner.avatarUrl} />
          </Styled.ImageContainer>
        </a>
        <Styled.Content>
          <a href="/" style={{ textDecoration: 'none' }}>
            <Styled.Title>Магазин «{storeName}»</Styled.Title>
          </a>

          <Styled.StoreInfo>
            <Styled.Text>
              {wordDeclination(props.productsCount, 'дизайн')}
            </Styled.Text>
            <Styled.EllipseIcon />
            <Styled.Text>{props.owner.name}</Styled.Text>
            <Styled.HideOnMobile>
              <Styled.EllipseIcon />
              <Styled.OwnerLink href="/profile/products">
                посмотреть профиль
              </Styled.OwnerLink>
            </Styled.HideOnMobile>
          </Styled.StoreInfo>
        </Styled.Content>
      </Styled.ContentWrapper>
      <Styled.LinksWrapper>
        <Styled.SeparateVBar />
        <ShareIcon />
        <Styled.ShareButton onClick={copyLink}>поделиться</Styled.ShareButton>
      </Styled.LinksWrapper>
      <GenericMessage status={status} open={message} text={messageText} />
    </Styled.ProfileWrapper>
  )
}

export default Profile
