import React, {useState} from "react";
import PropTypes from "prop-types";
import {Async as AsyncSelect} from "react-select";
import SearchIcon from "../../../common/Icons/SearchIcon";
import {
  loadSearchAutocomplete,
  searchAutocompleteEntry,
  searchOnKeyDown
} from "../../../../services/search";
import {Option} from "./Option";

import styles from "./search.module.scss";

const fontStyles = {
  fontFamily: `Formular, Arial, sans-serif`,
  fontSize: 16,
  color: `#949899`
};

const customStyles = {
  indicatorsContainer: () => ({display: `none`}),
  input: (provided) => ({
    ...provided,
    ...fontStyles
  }),
  valueContainer: (provided) => ({
    ...provided,
    ...fontStyles,
    paddingLeft: 45
  }),
  control: (provided) => ({
    ...provided,
    border: `none`,
    background: `#f5f6f6`,
    cursor: `text`
  })
};

export default function Search({isSticky, isShowing, isCustomStore}) {
  // Actually, this component should be managing it's opening state by itself
  // But the version we're using on the project (2.4.4 atm) doesn't support closeMenuOnScroll property
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <SearchIcon />
      </div>
      <AsyncSelect
        styles={customStyles}
        onChange={({value}) => searchAutocompleteEntry(value, isCustomStore)}
        components={{Option}}
        onKeyDown={(e) => searchOnKeyDown(e, isCustomStore)}
        loadOptions={loadSearchAutocomplete}
        loadingMessage={() => `Загрузка результатов...`}
        noOptionsMessage={({inputValue}) =>
          `По запросу ${inputValue} ничего не найдено`
        }
        onFocus={() => setIsMenuOpen(true)}
        onBlur={() => setIsMenuOpen(false)}
        placeholder='Поиск'
        autoFocus={!isSticky}
        menuIsOpen={isShowing && isMenuOpen}
        openMenuOnClick={false}
      />
    </div>
  );
}

Search.propTypes = {
  isSticky: PropTypes.bool,
  isShowing: PropTypes.bool
};
